// pdfListData.js
// pdfListData.js
import {
  FindalPDFOCTNOV,
  OCTNOV23,
  AUGSEP2023,
  FINALPDFMAY,
  JULYAUG22,
  MAYJUNE22,
  NOVDEC22,
  MARCHAPRILE22,
  FINALDEC22,
  HUNEJULY23,
  FINALFAV22,
  AUGSEPT14,
  SEPOCT22,
  SEPT2019,
  NOVDEC19,
  FEB2020,
  JAN2020,
  JAN2024,
  FEB2024,
  APRIL2024,
  MAY2024,
  JUNE2024,
  FORM,
  NEWS,
  JULY2024,
  AUGUST2024,
  September2024,
} from "./pdfFileNames";

export const pdfList = [
  {
    id: 24,
    name: "News 2024",
    path: NEWS,
    date: "2024-07-01",
  },
  {
    id: 23,
    name: "Form 2024",
    path: FORM,
    date: "2024-06-01",
  },
  {
    id: 27,
    name: "September 2024",
    path: September2024,
    date: "2024-08-01",
  },
  {
    id: 26,
    name: "August 2024",
    path: AUGUST2024,
    date: "2024-08-01",
  },
  {
    id: 25,
    name: "July 2024",
    path: JULY2024,
    date: "2024-07-01",
  },
  {
    id: 22,
    name: "June 2024",
    path: JUNE2024,
    date: "2024-06-01",
  },
  {
    id: 1,
    name: "May 2024",
    path: MAY2024,
    date: "2024-05-01",
  },
  {
    id: 2,
    name: "April 2024",
    path: APRIL2024,
    date: "2024-04-01",
  },
  {
    id: 3,
    name: "February 2024",
    path: FEB2024,
    date: "2024-02-01",
  },
  {
    id: 4,
    name: "January 2024",
    path: JAN2024,
    date: "2024-01-01",
  },
  {
    id: 5,
    name: "November 2023",
    path: OCTNOV23,
    date: "2023-10-10",
  },
  {
    id: 6,
    name: "September 2023",
    path: AUGSEP2023,
    date: "2023-8-10",
  },
  {
    id: 7,
    name: "July 2023",
    path: HUNEJULY23,
    date: "2023-06-23",
  },
  {
    id: 8,
    name: "May 2023",
    path: FINALPDFMAY,
    date: "2023-9-30",
  },
  {
    id: 9,
    name: "December 2022",
    path: NOVDEC22,
    date: "2022-12-30",
  },
  {
    id: 10,
    name: "October 2022",
    path: SEPOCT22,
    date: "2023-09-23",
  },
  {
    id: 11,
    name: "August 2022",
    path: JULYAUG22,
    date: "2022-7-10",
  },
  {
    id: 12,
    name: "June 2022",
    path: MAYJUNE22,
    date: "2022-5-1",
  },
  {
    id: 13,
    name: "April 2022",
    path: MARCHAPRILE22,
    date: "2022-04-10",
  },
  {
    id: 14,
    name: "February 2022",
    path: FINALFAV22,
    date: "2023-02-23",
  },
  {
    id: 15,
    name: "January 2022",
    path: FINALDEC22,
    date: "2022-12-21",
  },
  {
    id: 16,
    name: "November 2021",
    path: FindalPDFOCTNOV,
    date: "2023-11-30",
  },
  {
    id: 17,
    name: "September 2021",
    path: AUGSEPT14,
    date: "2023-08-23",
  },
  {
    id: 18,
    name: "February 2020",
    path: FEB2020,
    date: "2020-02-01",
  },
  {
    id: 19,
    name: "January 2020",
    path: JAN2020,
    date: "2020-01-01",
  },
  {
    id: 20,
    name: "December 2019",
    path: NOVDEC19,
    date: "2019-11-01",
  },
  {
    id: 21,
    name: "September 2019",
    path: SEPT2019,
    date: "2019-09-01",
  },
  // Updated name here
];

export const HOMEDATA = [
  {
    title: "लक्ष्य व उद्देश्य",
  },
  {
    subtext:
      "पुष्करणा बन्धुओं  के परस्पर भ्रातृभाव, सहकारिता, एकता व संगठन की. भावना की स्थापना व. प्रोत्साहन तथा उपर्युक्त लक्ष्य प्राप्ति हेतु समस्त भारत में परिषद्‌ की , प्रान्तीय व. स्थानीय शाखाएँ स्थापित करना व उनका नियमन एवं संचालन करना |",
  },
  {
    subtext:
      "पुष्करणा बन्धुओं की सामाजिक, शैक्षणिक, आर्थिक, सांस्कृतिक उन्नति, प्रगति व कल्याण की अभिवृद्धि हेतु कार्य, एवं. प्रयत्न करना |",
  },
  {
    subtext:
      "उच्च व तकनीकी शिक्षा प्राप्ति हेतु साधनहीन विधार्थियों की तथा आपातकालीन  अवस्थान में  जरूरतमन्द पुष्करणा - बन्धुओं की आर्थिक व अन्य सहायता करना |",
  },
  {
    subtext:
      "पुष्करणा बन्धुओं में पारस्परिक सम्पर्क बढ़ाने, उन्हें एक दूसरे के निकट लाने ढेतु क्षेत्रीय, विभागीय व. अखिल भारतीय सम्मेलन के अधिवेशन, स्नेह मिलन, सभा समारोह, उत्सव, मनोरंजन के लिए आम खेल प्रतियोगिताओं आदि का आयोजन करना तथा पत्र. पत्रिकाओं व अन्य साहित्य का प्रकाशन व प्रसारण करना तथा प्रचार व प्रसार के सभी साधनों को काम में लेना |",
  },
  {
    subtext:
      "महिला मण्डल, युवक मण्डल, व्यावसायिक मण्डल तथा 'ऐसे ही. अन्य. उपयोगी. मण्डलों _ को. प्रोत्साहित करना. और आवश्यक साधन उपलब्ध कर, उनकी हर प्रकार से. सहायता करना।",
  },
  {
    subtext:
      "परिषद्‌ के उद्देश्यों की पूर्ति हेतु विधालय, प्ात्रावास, पुस्तकालय, जौशाला, कृषिफार्म, अनुसंधानशालाओं, वृद्धआश्रम, चिकित्सालय व औषधालय आदि का संचालन करना |",
  },
  {
    subtext:
      "परिषद्‌ व उसकी समस् शाखाओं के बहुमुखी कार्य कलापों को गतिमान बनाने हेतु शुल्क, दान, मनोर॑जक कार्यकम, खेल-तमाशे व अन्य साधनों द्वारा ऊर्थ - उपार्जन करना एवं उसे परिषद के जय पा पाएँ गए गए गया |",
  },
  {
    subtext:
      "सभी अथवा आंशिक समान उद्देश्यों वाली. अन्य स्व-सामाजिक, अन्य सामाजिक-सांस्कृतिक संस्थाओं से अपने कार्य के सम्बन्ध में समन्वय स्थापित करना ।",
  },
  {
    subtext:
      "परिषद्‌ के लक्ष्यों व उद्देश्यों की पूर्ति हेतु सम्पतियों, भवनों को धारण करना, खरीदना, निर्माण करवाना तथा आवश्यकता अनुसार 'हस्तान्तरण करना ।",
  },
  {
    subtext:
      "पुष्टिकर सेवा परिषद्‌ ऐसे कार्यकम भी आयोजित करेगी, जिनसे देश के नागरिकों में राष्ट्रीयता की भावना, उच्च मानवीय जीवन - मूल्यों के प्रति आस्था एवं भारतीय सभ्यता व संस्कृति के प्रति निष्ठा विकसित हो ।",
  },
];
