import React from "react";
import Basic from "./Basic/Basic";
import { Typography, Grid, Box } from "@mui/material";
import Banner from "./Banner";
import News1 from "../Assets/CorrespondenceImages/News1.jpeg";

const Correspondence = () => {
  // const cardTypo1Animation = keyframes`
  //   0% {
  //     transform: translateX(100%);
  //   }
  //   100% {
  //     transform: translateX(-100%);
  //   }
  // `;
  const styles = {
    cardSize: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      margin: "30px 0",
      padding: "20px 15px",
      cursor: "pointer",
      textAlign: "start",
      backgroundColor: "#f9f9f9",
      "&:hover": {
        boxShadow: "2px 3px 11px 4px rgba(248, 237, 198, 0.86)",
      },
    },
    cardTypo1: {
      fontWeight: 600,
      fontSize: "20px",
      color: "#f69740",
      textAlign: { lg: "center" },
      // animation: `${cardTypo1Animation} 20s infinite linear`,
    },
    cardTypo2: {
      fontWeight: 600,
      fontSize: "16px",
      textAlign: { lg: "center" },
      margin: "5px 0px",
    },
    cardTypo3: {
      fontWeight: 600,
      fontSize: "16px",
      textAlign: { lg: "center" },
      marginBottom: "20px",
    },
    cardBox2Typo: {
      display: "flex",
      justifyContent: "space-between",
      width: { lg: "70%" },
      margin: { lg: "0 auto" },
    },
    typoWidth: {
      width: { lg: "70%" },
      margin: { lg: "0 auto" },
    },
    typoFont: {
      fontSize: "18px",
    },
  };
  return (
    <Basic>
      <Grid container spacing={2} sx={{ textAlign: "center" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{
            // display: { xs: "none", sm: "block" },
            marginTop: { xs: "4rem" },
          }}
        >
          <Banner />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography
            // textAlign="center"
            variant="h4"
            sx={{
              fontFamily: "Libre Baskerville,serif",
              fontWeight: 600,
              fontSize: "50px",
              color: "rgb(251,151,63)",
              marginTop: "1rem",
            }}
          >
            Correspondence
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ margin: "30px" }}
        >
          {/* <Card sx={styles.cardSize}> */}
          <Box>
            <Typography sx={styles.cardTypo1}>
              All subscribers and advertisers of Pushkarna Sandesh are hereby
              informed that from 1.11.2018 following account will be used.
            </Typography>
            <Typography sx={styles.cardTypo2}>
              Hence send your amount in the following account.
            </Typography>
            {/* <Typography sx={styles.cardTypo3}>
              Akhil Bhartiya Pushtikar sewa Parishad
            </Typography> */}
          </Box>
          <Box style={{ marginTop: "15px" }}>
            <Box sx={styles.cardBox2Typo}>
              <Typography sx={styles.typoFont}>खाता नाम : </Typography>
              <Typography
                sx={styles.typoFont}
                style={{ fontWeight: "inherit" }}
              >
                अखिल भारतीय पुष्टिकर सेवा परिषद्
              </Typography>
            </Box>
            <Box sx={styles.cardBox2Typo}>
              <Typography sx={styles.typoFont}>खाता संख्या : </Typography>
              <Typography sx={styles.typoFont} style={{ fontWeight: "bold" }}>
                51015132817
              </Typography>
            </Box>

            <Box sx={styles.cardBox2Typo}>
              <Typography sx={styles.typoFont}>बैंक का नाम : </Typography>
              <Box>
                <Typography
                  align="right"
                  sx={styles.typoFont}
                  style={{ fontWeight: "bold" }}
                >
                  स्टेट बैंक ऑफ इंडिया
                </Typography>
                <Typography sx={styles.typoFont}>
                  दाऊ जी रोड शाखा, बीकानेर
                </Typography>
              </Box>
            </Box>
            <Box sx={styles.cardBox2Typo}>
              <Typography sx={styles.typoFont}>आई.एफ.एस.सी कोड : </Typography>
              <Typography sx={styles.typoFont} style={{ fontWeight: "bold" }}>
                SBIN0031447
              </Typography>
            </Box>
            <Box sx={styles.typoWidth} style={{ marginTop: "5px" }}>
              <Typography sx={styles.typoFont}>
                <span style={{ fontWeight: "bold" }}>नोट :</span> शुल्क जमा
                कराने की रसीद की फोटो निम्नांकित whatsapp नम्बर पर अवश्य प्रेषित
                करें।
              </Typography>
              <Typography sx={styles.typoFont} style={{ fontWeight: "bold" }}>
                डॉ. एस. एन. हर्ष. : 9414140004
              </Typography>
              <Typography sx={styles.typoFont}>
                पुष्करणा सन्देश की PDF प्रति प्राप्त करने के लिये अपना नाम,
                ई-मेल, या whatsapp नम्बर अवश्य सूचित करें। ज्ञात रहे कि पुष्करणा
                सन्देश निःशुल्क (PDF) प्रेषित की जाती है।
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <Box>
                  <Typography
                    sx={styles.typoFont}
                    style={{ fontWeight: "bold" }}
                  >
                    प्रकाश छंगाणी
                  </Typography>
                  <Typography sx={styles.typoFont}>
                    राष्ट्रीय अध्यक्ष
                  </Typography>
                </Box>
                <Box>
                  <Typography sx={styles.typoFont}>धन्यवाद</Typography>
                </Box>
                <Box>
                  <Typography
                    sx={styles.typoFont}
                    style={{ fontWeight: "bold" }}
                  >
                    डॉ. एस. एन. हर्ष
                  </Typography>
                  <Typography sx={styles.typoFont}>
                    राष्ट्रीय महामंत्री
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* </Card> */}
        </Grid>
      </Grid>
      <Grid container sx={{ display: "flex", justifyContent: "center" }}>
        <Grid item sx={{ marginBottom: "50px" }}>
          <Box>
            <img src={News1} alt="news1" height="100%" width="100%" />
          </Box>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "50px",
          }}
        >
          <iframe
            title="PDF Viewer"
            src={`https://docs.google.com/gview?url=${"https://intranet.mobiloittegroup.com/uploads/estimations/uploads/ABPSP.pdf"}&embedded=true`}
            // src="https://intranet.mobiloittegroup.com/uploads/estimations/uploads/ABPSP.pdf"
            width="100%"
            height="650px"
          />
        </Grid>
      </Grid>
    </Basic>
  );
};

export default Correspondence;

// const Newselter = () => {
//   const underlineTransition = {
//     position: "relative",
//     "&::after": {
//       content: '""',
//       position: "absolute",
//       bottom: 0,
//       left: 0,
//       width: "5%",
//       height: "2px",
//       backgroundColor: "rgb(251,151,63)", // Change color as needed
//       transition: "width 0.3s ease-in-out", // Transition effect
//     },
//     "&:hover::after": {
//       width: "35%", // Expand underline on hover
//       "@media (min-width:600px)": {
//         width: "19%", // Adjust for different screen sizes if needed
//       },
//       "@media (min-width:960px)": {
//         width: "23%", // Adjust for different screen sizes if needed
//       },
//       "@media (min-width:1280px)": {
//         width: "19%", // Adjust for different screen sizes if needed
//       },
//     },
//   };
//   return (
//     <>
//       <Grid container spacing={1}>
//         <Grid item xs={12}>
//           <Typography
//             textAlign="center"
//             marginBottom="15px"
//             variant="h4"
//             sx={{ fontFamily: "Libre Baskerville,serif", color: "#3f51b5",marginTop:{xs:"3rem"}}}
//           >
//             Newsellter
//           </Typography>
//         </Grid>
//         <Grid
//           item
//           xs={12}
//           md={6}
//           sx={{
//             order: { xs: 2, md: 1 },
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Box sx={{ width: "80%", height: "95%",marginLeft:{md:"-6rem", xs:"0rem"},boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.9)", borderRadius: "0.5rem" }}>
//             <img
//               src={Download}
//               alt="Aestronote"
//               style={{
//                 width: "100%",
//                 height: "100%",
//                 objectFit: "cover",
//                 borderRadius: "0.5rem",
//               }}
//             />
//           </Box>
//         </Grid>
//         <Grid item xs={12} md={6} sx={{ order: { xs: 1, md: 2 } }}>
//         <Box sx={{width:"100%", height:"100%" ,marginLeft:{md:"-2rem", xs:"0rem"}}}>

//           <Typography
//             variant="h4"
//             sx={{
//               fontFamily: "Libre Baskerville,serif",
//               marginLeft: "1rem",
//               marginTop: "2rem",
//               ...underlineTransition,
//             }}
//           >

//             Newsletter
//           </Typography>
//           <Typography variant="body1" sx={{ padding: 2, textAlign: "justify" }}>
//             Hinduism is one of the world's oldest religions, often regarded as a
//             way of life or a cultural identity as much as it is a religious
//             belief system. It originated in the Indian subcontinent and is
//             diverse, encompassing a wide spectrum of beliefs, rituals,
//             practices, and philosophies.n
//           </Typography>
//           <Typography variant="body1" sx={{ padding: 2, textAlign: "justify" }}>
//             Hinduism is one of the world's oldest religions, often regarded as a
//             way of life or a cultural identity as much as it is a religious
//             belief system. It originated in the Indian subcontinent and is
//             diverse, encompassing a wide spectrum of beliefs, rituals,
//             practices, and philosophies.n
//             Hinduism is one of the world's oldest religions, often regarded as a
//             way of life or a cultural identity as much as it is a religious
//             belief system. It originated in the Indian subcontinent and is
//             diverse, encompassing a wide spectrum of beliefs, rituals,
//             practices, and philosophies.n
//           </Typography>
//           </Box>
//         </Grid>
//       </Grid>
//     </>
//   );
// };

// export default Newselter;
